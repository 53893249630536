import MobileDetect from "mobile-detect";

const md = new MobileDetect(window.navigator.userAgent);

function getWooltariiOSVersion() {
  if (md.match("WooltariiOS")) {
    const userAgent = window.navigator.userAgent;

    const version = userAgent.match(/WooltariiOS\/(\d+)/)[1];

    return parseInt(version);
  }

  return null;
}

export default {
  wooltariiOSVer: getWooltariiOSVersion(),
  iOSVer: md.version("iOS") ? md.version("iOS") : null,
  androidVer: md.version("Android") ? md.version("Android") : null,
  isIOS: md.os() === "iOS",
  isIOS8:
    md.os() === "iOS" &&
    parseFloat(md.version("iOS")) >= 8 &&
    parseFloat(md.version("iOS")) < 9,
  isIOS9:
    md.os() === "iOS" &&
    parseFloat(md.version("iOS")) >= 9 &&
    parseFloat(md.version("iOS")) < 10,
  isIOS10:
    md.os() === "iOS" &&
    parseFloat(md.version("iOS")) >= 10 &&
    parseFloat(md.version("iOS")) < 11,
  isAndroid: md.os() === "AndroidOS",
  isMobile: md.mobile() !== null ? true : false,
  isApp:
    md.match("Wooltari/Android") || md.match("Wooltari/iOS") ? true : false,
};
