// DOM 로드를 확인하고 함수를 실행하는 도구
export const App = (fn) => {
  if (document.readyState != "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
};

export default App;
